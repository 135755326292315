const MAX_CITY_LENGTH = 4;
const MAX_STREET_LENGTH = 10;

function combineCityStreet(_city, _street, _maxCityLength = undefined, _maxStreetLength = undefined) {
	const city = _city || '...',
		street = _street || '...',
		maxCityLength = _maxCityLength || MAX_CITY_LENGTH,
		maxStreetLength = _maxStreetLength || MAX_STREET_LENGTH;

	const cutCity = city.length > maxCityLength,
		cutStreet = street.length > maxStreetLength;

	return (
		city.slice(0, maxCityLength) +
		(cutCity ? '.' : '') +
		': ' +
		(cutStreet ? '...' : '') +
		street.slice(cutStreet ? street.length - (maxStreetLength - 2) : 0)
	);
}

export { combineCityStreet };
