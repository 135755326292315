import React, { useState, useRef, useEffect } from 'react';
import { useQuery, useLazyQuery, gql } from '@apollo/client';
import { format as _formatDate } from 'date-fns';

import { UNIT_CLASSIFICATIONS } from '../connect-front/src/constants';
import * as connectFrontColors from '../connect-front/src/colors';
import { getFormattedPrice } from '../connect-front/src/utility-functions';
import { OverviewChart } from '../connect-front/src/components/OverviewChart';
import { OverviewMiniInfo } from '../connect-front/src/components/OverviewMiniInfo';
import MostAlarmsChart from '../connect-front/src/components/MostAlarmsChart';
import TrendsChart from '../connect-front/src/components/TrendsChart';
import i18n from '../i18n';

import { BODY_PROPS } from '../constants';
import { useTranslation } from 'react-i18next';

function renderEnergyCostHtml(cost, energyTypeString) {
	return (
		<div style={{ display: 'flex' }}>
			<h2 style={{ margin: '0', fontWeight: '400', width: '2.8rem' }}>{getFormattedPrice(cost)} </h2>
			<h3 style={{ fontWeight: '400', margin: '0.3rem 0 0.3rem 0.5rem', zIndex: '1' }}>
				{cost < 1000 ? 'kr' : 'tkr'} {energyTypeString}.
			</h3>
		</div>
	);
}
const graphHeaderStyle = { margin: '1.0rem 0 0.5rem 1.2rem', color: '#333', fontSize: '155%', fontWeight: '500' };
const chartProps = { animate: false, isInteractive: false, useMesh: false };
const priceCardStyle = {
	position: 'relative',
	margin: '0 1.7rem 0 0',
	color: '#f8f8f8',
	padding: '1rem 1rem',
	borderRadius: '0.3rem',
};
const barChartSize = { width: '13rem', height: '8rem' };

export default function OverviewPage({ props, energySensors, alarmLogs, properties, energyConsumptionCosts, INTERVAL }) {
	const { t } = useTranslation();
	const totalEnergyCost = Object.values(energyConsumptionCosts).reduce((sum, cur) => sum + cur);
	const trendsChartLocalization = {
		subheader:
			t('document.under') +
			(INTERVAL.label.search(/[0-9]/) === -1 ? t('document.this') : t('document.these')) +
			INTERVAL.label.toLowerCase() +
			t('document.period'),
	};

	return (
		<>
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: '33% auto 33%',
					width: BODY_PROPS.chartWidth,
					margin: '0rem auto 0',
				}}
			>
				<OverviewMiniInfo
					label={t('document.connectedBuildings')}
					value={properties?.length || 0}
					image={require('../connect-front/src/assets/dummy-charts/bar-chart.png')}
					color={connectFrontColors.secondaryA}
					style={{ gridColumn: '1 / 2', margin: '0 0.7rem' }}
				/>
				<OverviewMiniInfo
					label={t('document.totalAlarms')}
					value={alarmLogs?.length || 0}
					image={require('../connect-front/src/assets/dummy-charts/line-chart.png')}
					color={connectFrontColors.secondaryB}
					style={{ gridColumn: '2 / 3', margin: '0 0.7rem' }}
				/>
				<OverviewMiniInfo
					label={t('document.nonAcknowledgedNotices')}
					value={alarmLogs?.filter(ala => !ala.fixed).length || 0}
					image={require('../connect-front/src/assets/dummy-charts/pie-chart.png')}
					color={connectFrontColors.secondaryC}
					style={{ gridColumn: '3 / 4', margin: '0 0.7rem' }}
				/>
			</div>
			<div style={{ width: BODY_PROPS.chartWidth, margin: '2.8rem auto 0' }}>
				<h2 style={graphHeaderStyle}>{t('document.totalEnergyUsage')}</h2>
				<OverviewChart sensors={energySensors} interval={INTERVAL} showOptions={false} chartProps={chartProps} />
			</div>

			<div style={{ display: 'flex', margin: '2.8rem auto 0' }}>
				{/* Two nested flex divs are required for the second to be centered within the first */}
				<div style={{ display: 'flex', margin: '0 auto' }}>
					{/* Total cost under duration */}
					<div
						style={{
							...priceCardStyle,
							background: connectFrontColors.secondaryA,
						}}
					>
						<h3 style={{ fontSize: '120%', fontWeight: '450', margin: '0 0 0.2rem 0' }}>{t('document.totalCost')}</h3>
						<div style={{ display: 'flex' }}>
							<h1 style={{ fontWeight: '400', margin: '0' }}>{getFormattedPrice(totalEnergyCost)} </h1>
							<h3 style={{ fontWeight: '400', margin: '0.65rem 0 0 0.5rem' }}>{totalEnergyCost < 1000 ? 'kr' : 'tkr'}</h3>
						</div>
						<h3 style={{ fontWeight: '400', margin: '1.90rem 0 0 0' }}>
							{t('document.under')} {INTERVAL.label.search(/[0-9]/) === -1 ? t('document.this') : t('document.these')}{' '}
							{INTERVAL.label.toLowerCase()}.
						</h3>
					</div>

					{/* Per energy type cost under duration */}
					<div
						style={{
							...priceCardStyle,
							background: connectFrontColors.secondaryB,
						}}
					>
						<h3 style={{ fontSize: '120%', fontWeight: '450', margin: '0 0 0.2rem 0' }}>{t('document.cost')}</h3>
						{renderEnergyCostHtml(energyConsumptionCosts.electricity, t('document.electricity'))}
						{renderEnergyCostHtml(energyConsumptionCosts.water, t('document.water'))}
						{renderEnergyCostHtml(energyConsumptionCosts.districtHeating, t('document.districtHeating'))}
					</div>

					{/* Energy type cost modification */}
					<div
						style={{
							...priceCardStyle,
							background: connectFrontColors.secondaryC,
							marginRight: '0',
						}}
					>
						<h3 style={{ fontSize: '120%', fontWeight: '450', margin: '0 0 0.2rem 0' }}>{t('document.energyPrices')}</h3>
						{Object.values(UNIT_CLASSIFICATIONS)
							.slice(0, 3)
							.map(type => (
								<div key={type.id} style={{ display: 'flex', margin: '0.65rem 0 0 0' }}>
									<h3 style={{ fontSize: '110%', fontWeight: '400', width: '9.4rem' }}>
										{type.label} {t('document.per')} {type.unit}:
									</h3>
									<h3 style={{ fontSize: '110%', fontWeight: '400', width: '2.0rem' }}>
										{props.user?.energyPrices?.[type.energyIndex] || type.priceMultiplier}
									</h3>
								</div>
							))}
					</div>
				</div>
			</div>

			<div style={{ display: 'flex', margin: '2.8rem auto 0' }}>
				<div style={{ display: 'flex', margin: '0 auto' }}>
					{/* Most alarms */}
					<MostAlarmsChart alarms={alarmLogs} properties={properties} size={barChartSize} chartProps={chartProps} />

					{/* Negative trends */}
					<TrendsChart
						selectedInterval={INTERVAL}
						sensors={JSON.parse(JSON.stringify(energySensors))}
						size={barChartSize}
						localization={trendsChartLocalization}
						style={{ marginLeft: '1.5rem' }}
						chartProps={chartProps}
					/>

					{/* Positive trends */}
					<TrendsChart
						negativeTrend
						selectedInterval={INTERVAL}
						sensors={JSON.parse(JSON.stringify(energySensors))}
						size={barChartSize}
						localization={trendsChartLocalization}
						style={{ marginLeft: '1.5rem' }}
						chartProps={chartProps}
					/>
				</div>
			</div>
		</>
	);
}
