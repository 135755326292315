import React from 'react';
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from 'apollo-link-context';
import jwt from 'jsonwebtoken';
import base64url from 'base64-url';
import { MuiThemeProvider, createMuiTheme, CssBaseline } from '@material-ui/core';
import i18n from './i18n';

import * as connectFrontColors from './connect-front/src/colors';

import { IGNORE_QUERY_STRING_USER } from './constants';
import DocumentTwo from './components/Document2';

const GRAPHQL_SERVER_URL = process.env.REACT_APP_GRAPHQL_SERVER_URL;

const MUI_THEME = createMuiTheme({
	palette: {
		primary: {
			main: connectFrontColors.primary,
		},
		secondary: {
			main: connectFrontColors.accent,
		},
	},
	typography: {
		useNextVariants: true,
		fontFamily: [
			'Roboto',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
	},
});

function App() {
	const queryStrings = new URLSearchParams(window.location.search);
	const queryStrUser = queryStrings.get('user');

	let user;
	if (queryStrUser)
		try {
			if (queryStrUser === IGNORE_QUERY_STRING_USER) user = jwt.decode(queryStrings.get('token'));
			else user = JSON.parse(base64url.decode(queryStrUser));
		} catch (e) {
			console.warn(e);
		}

	if (!queryStrings.get('start') || !queryStrings.get('end') || !user || !queryStrings.get('token'))
		return (
			<div>
				Missing or invalid query strings.
				<br />
				Required query strings are &lt;start&gt;, &lt;end&gt;, &lt;user&gt;, &lt;token&gt;.
			</div>
		);

	// Set language to user language based on jwt token or default to Swedish
	i18n.changeLanguage(user.language || 'en');

	return (
		<ApolloProvider
			client={
				new ApolloClient({
					cache: new InMemoryCache(),
					link: setContext((_, { headers }) => {
						return {
							headers: {
								...headers,
								authorization: queryStrings.get('token'),
							},
						};
					}).concat(
						createHttpLink({
							uri: GRAPHQL_SERVER_URL,
						})
					),
				})
			}
		>
			<MuiThemeProvider theme={MUI_THEME}>
				<CssBaseline>
					<DocumentTwo user={user} />
				</CssBaseline>
			</MuiThemeProvider>
		</ApolloProvider>
	);
}

export default App;
