/**
 * Format a price to be more readable
 * @param {Number / String} price : Price to be formatted
 * @returns {String} : Formatted price
 */
function getFormattedPrice(price) {
	if (isNaN(price)) return price;

	let r = Number(price);

	if (r >= 1000) r = r / 1000;

	if (price >= 1000 && r < 100) r = r.toFixed(1);
	else r = r.toFixed(0);

	r = r.replace('.', ',');

	return r;
}

export { getFormattedPrice };
