/**
 * Interpolates missing (null) values in data
 * @param {any[]} _data : Data to be interpolated
 * @param {(any) => number | null} getValue : Returns value of an element in the array
 * @param {(any, number) => void} setValue : Sets value of an element in the array
 * @returns {any[]} Interpolated data
 */
function getInterpolatedData(_data, getValue, setValue, interpolateBeginning = false) {
	let data = JSON.parse(JSON.stringify(_data || []));
	if (data.every(dat => getValue(dat) === null)) return data; // Exit if all values are null

	let beginning = [];
	if (!interpolateBeginning) {
		const firstValI = data.findIndex(dat => getValue(dat) !== null);
		beginning = data.slice(0, firstValI);
		data = data.slice(firstValI);
	}

	function getEdgeValue(startI = 0, leftEdge = true) {
		for (let i = startI; leftEdge ? i < data.length : i >= 0; leftEdge ? ++i : --i) {
			const val = getValue(data[i]);
			if (val !== null) return val;
		}
	}

	function getInterpolatedValue(index) {
		const lastVal = getValue(data[index - 1]);
		let nextVal,
			nextI = index;

		while (nextVal === undefined) {
			const val = getValue(data[++nextI]);
			if (val !== null) nextVal = val;
		}

		return lastVal + (nextVal - lastVal) / (nextI - (index - 1));
	}

	// Set first and last values in array
	if (getValue(data[0]) === null) setValue(data[0], getEdgeValue(1, true));
	if (getValue(data[data.length - 1]) === null) setValue(data[data.length - 1], getEdgeValue(data.length - 2, false));

	for (const [i, dat] of data.entries()) {
		if (getValue(dat) === null) setValue(dat, getInterpolatedValue(i));
	}

	return beginning.concat(data);
}

export { getInterpolatedData };
