import React from 'react';
import { ResponsiveBar } from '@nivo/bar';

import { GRAPH_INTERVAL_TYPES } from '../connect-front/src/constants';
import * as colors from '../connect-front/src/colors';
import { getFormattedNumber, combineCityStreet } from '../connect-front/src/utility-functions';
import { CustomLegends } from '../connect-front/src/components/NivoCharts';

const COLORS = [
	'#D0DADF',
	colors.secondaryA,
	colors.secondaryB,
	colors.secondaryC,
	colors.secondaryD,
	colors.secondaryE,
	colors.secondaryF,
	colors.secondaryG,
];
const MAX_BARS_PER_CHART = 16;

/**
 * Draws as many charts as are required in order to show provided data
 * @param {object[]} data : Formatted for Nivo bar-charts and pre-sorted
 * @param {object} chartProps : Passed directly to the charts
 * @param {object} customLegends : For adding custom legends. Format: {legends: {label: String, color: String}[], style: CSS}
 */
function BarCharts(props) {
	if (!props.data?.length) return null;

	return (
		<div>
			{(Array.isArray(props.data[0])
				? props.data
				: props.data.reduce(
						(res, cur) => {
							if (res[res.length - 1].length < MAX_BARS_PER_CHART) res[res.length - 1].push(cur);
							else res.push([cur]);
							return res;
						},
						[[]]
				  )
			).map((data, rowI) => (
				<div key={rowI}>
					<div className='page-break' style={{ display: rowI && rowI % 5 === 0 ? '' : 'none' }} />

					<div
						style={{
							position: 'relative',
							height: '14.6rem',
							marginBottom: '0.3rem',
							marginTop: rowI && rowI % 5 === 0 ? '1.8rem' : '0',
						}}
					>
						<ResponsiveBar
							data={(() => {
								const keys = {};
								for (const key of props.chartProps?.keys || []) keys[key] = 0;
								while (data.length < MAX_BARS_PER_CHART) data.push({ ...keys, [props.chartProps?.indexBy]: Math.random() });
								return data;
							})()}
							padding={0.2}
							innerPadding={2}
							margin={{ top: 10, right: 60, bottom: 60, left: 60 }}
							colorBy={node =>
								node.id === GRAPH_INTERVAL_TYPES.current
									? node.index % COLORS.length || Math.floor(2 + Math.random() * (COLORS.length - 4))
									: 0
							}
							enableLabel={false}
							isInteractive={false}
							animate={false}
							borderRadius={1}
							borderWidth={1}
							borderColor={{ from: 'color', modifiers: [['darker', '0.8']] }}
							colors={COLORS}
							axisLeft={{
								renderTick: tick => (
									<text
										x={tick.x - 10}
										y={tick.y}
										textAnchor='end'
										dominantBaseline='middle'
										style={{
											fill: '#333',
											fontSize: 11,
										}}
									>
										{getFormattedNumber(tick.value)}
									</text>
								),
							}}
							axisBottom={{
								tickRotation: 20,
								format: val => {
									const sen = (Array.isArray(props.data[0]) ? props.data.flat() : props.data).find(
										sen => sen.sensorid === val
									);
									return sen?.city || sen?.street ? combineCityStreet(sen.city, sen.street, 4, 12) : '';
								},
							}}
							{...props.chartProps}
						/>

						<CustomLegends
							{...props.customLegends}
							style={{
								...props.customLegends.style,
								position: 'absolute',
								transform: 'scale(0.8)',
								top: '0.4rem',
								right: '-2.6rem',
							}}
						/>
					</div>
				</div>
			))}
		</div>
	);
}

export default BarCharts;
