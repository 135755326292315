import * as connectFrontColors from './connect-front/src/colors';

export const COLORS = {
	connectPrimary: connectFrontColors.bgcolor,
	success: connectFrontColors.success,
	failure: connectFrontColors.failure,
};

export const HEADER_PROPS = { height: '5.4rem', color: COLORS.connectPrimary };

export const BODY_PROPS = { width: '60rem', chartWidth: '40rem' };

export const IGNORE_QUERY_STRING_USER = 'AUTHORIZATION-TOKEN';

export const REPORT_OPTIONS = {
	drawOverviewPage: 'DRAW_OVERVIEW_PAGE',
	drawEnergyDataPage: 'DRAW_ENERGY_DATA_PAGE',
	drawElectricityComparisonPage: 'DRAW_ELECTRICITY_COMPARISON_PAGE',
	drawWaterComparisonPage: 'DRAW_WATER_COMPARISON_PAGE',
	drawDistrictHeatingComparisonPage: 'DRAW_DISTRICT_HEATING_COMPARISON_PAGE',
	drawChartPage: 'DRAW_CHART_PAGE',
	drawAlarmPage: 'DRAW_ALARM_PAGE',
};
