import React, { useState, useRef, useEffect } from 'react';
import { useQuery, useLazyQuery, gql } from '@apollo/client';

import { GRAPH_INTERVAL_TYPES } from '../connect-front/src/constants';
import * as connectFrontColors from '../connect-front/src/colors';
import BarCharts from './BarCharts';
import { useTranslation } from 'react-i18next';
import { format as _formatDate } from 'date-fns';
import { getFormattedNumber } from '../connect-front/src/utility-functions';
import Table from './Table';

export function ComparisonBarChart({ energySensors, unitId }) {
	const { t } = useTranslation();
	return (
		<BarCharts
			data={(() => {
				const sens = energySensors
					.filter(sen => sen.classification === unitId)
					.map(sen => {
						const retSen = {
							...sen,
							[GRAPH_INTERVAL_TYPES.former]: sen.eleComData?.[0]?.y || 0,
							[GRAPH_INTERVAL_TYPES.current]: sen.eleComData?.[1]?.y || 0,
						};
						return retSen;
					})
					.sort((a, b) =>
						a[GRAPH_INTERVAL_TYPES.current] > b[GRAPH_INTERVAL_TYPES.current]
							? -1
							: a[GRAPH_INTERVAL_TYPES.current] < b[GRAPH_INTERVAL_TYPES.current]
							  ? 1
							  : a[GRAPH_INTERVAL_TYPES.former] > b[GRAPH_INTERVAL_TYPES.former]
							    ? -1
							    : 1
					);
				let startVal = Number.MAX_SAFE_INTEGER;
				const groupedSens = [];
				for (const sen of sens) {
					if (
						groupedSens[groupedSens.length - 1]?.length < 16 &&
						(sen[GRAPH_INTERVAL_TYPES.current] / startVal > 0.22 ||
							sen[GRAPH_INTERVAL_TYPES.current] < (sens[0]?.[GRAPH_INTERVAL_TYPES.current] || 1) / 100)
					)
						groupedSens[groupedSens.length - 1].push(sen);
					else {
						groupedSens.push([sen]);
						startVal = sen[GRAPH_INTERVAL_TYPES.current];
					}
				}
				return groupedSens;
			})()}
			chartProps={{
				indexBy: 'sensorid',
				keys: [GRAPH_INTERVAL_TYPES.former, GRAPH_INTERVAL_TYPES.current],
				groupMode: 'grouped',
			}}
			customLegends={{
				legends: [
					{ label: t('document.previousP'), color: '#D0DADF' },
					{ label: t('document.currentP'), color: connectFrontColors.secondaryA },
				],
			}}
		/>
	);
}

export function ComparisonTable({ energySensors, unitId, unit }) {
	const { t } = useTranslation();
	return (
		<Table
			data={energySensors
				.filter(sen => sen.classification === unitId)
				.map(sen => ({
					...sen,
					[GRAPH_INTERVAL_TYPES.former]: getFormattedNumber(sen.eleComData?.[0]?.y || 0) + ' ' + unit,
					[GRAPH_INTERVAL_TYPES.current]: getFormattedNumber(sen.eleComData?.[1]?.y || 0) + ' ' + unit,
					change: getFormattedNumber(((sen.eleComData?.[1]?.y || 1) / (sen.eleComData?.[0]?.y || 1)) * 100 - 100, true) + ' %',
				}))
				.sort((a, b) => {
					return Number(a[GRAPH_INTERVAL_TYPES.current].split(' ')[0]) > Number(b[GRAPH_INTERVAL_TYPES.current].split(' ')[0])
						? -1
						: 1;
				})
				.sort((a, b) => {})}
			tableProps={{
				columns: [
					{ title: t('generic.city'), field: 'city' },
					{ title: t('generic.address'), field: 'street', truncateLeft: true },
					{ title: t('generic.name'), field: 'name', truncateLeft: true },
					{ title: t('document.current'), field: GRAPH_INTERVAL_TYPES.current },
					{ title: t('document.previous'), field: GRAPH_INTERVAL_TYPES.former },
					{ title: t('document.change'), field: 'change' },
				],
				maxColumnLength: 24,
			}}
		/>
	);
}
