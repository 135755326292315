import React from 'react';

import * as connectFrontColors from '../connect-front/src/colors';
import MaterialTableWrapper from '../connect-front/src/components/MaterialTableWrapper';

/**
 * Draws tables that automatically split when exceeding the length of a  page
 * @param {object[]} data : Shown in tables, should be presorted
 * @param {object} tableProps : Passed directly to MaterialTableWrapper
 */
function Table(props) {
	return (
		<div>
			{props.data
				.sort((a, b) => (new Date(a.earliestAlarm) < new Date(b.earliestAlarm) ? -1 : 1))
				.reduce(
					(sum, cur) => {
						if (sum[sum.length - 1].length < 36) sum[sum.length - 1].push(cur);
						else sum.push([cur]);
						return sum;
					},
					[[]]
				)
				.map((logChunk, chunkI, logChunks) => (
					<div key={logChunk[0]?.sensorid || 0}>
						<MaterialTableWrapper
							{...props.tableProps}
							data={logChunk}
							options={{
								toolbar: false,
								paging: false,
								sorting: false,
								headerStyle: { color: '#333', background: connectFrontColors.websiteBg, fontWeight: '600' },
								rowStyle: { background: connectFrontColors.websiteBg },
							}}
							reducePadding
							style={{
								transform: 'scale(0.95)',
								transformOrigin: 'center top',
								marginTop: '2rem',
								boxShadow: 'none',
							}}
						/>

						{chunkI !== logChunks.length - 1 ? <div className='page-break' /> : null}
					</div>
				))}
		</div>
	);
}

export default Table;
