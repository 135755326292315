import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './connect-front/src/i18n';
import './i18n';
import App from './App';

ReactDOM.render(
	// Material-UI with Material-Table does not yet play nicely with StrictMode
	// <React.StrictMode>
	<App />,
	// </React.StrictMode>
	document.getElementById('root')
);
